.video-wave {
  margin: 0 24px;
  padding: 0 4px;
  border-radius: 0 0 6px 6px;
  border: solid 1px #000;
}
.video-wave > div {
  max-width: 640px;
  max-height: 80px;
}
