.video-sources {
  justify-content: center;
  padding: 16px;
  color: white;
  font-weight: 500;
  text-align: center;
}

.video-sources__select {
  background: #000;
  color: #fff;
  padding: 4px 8px;
  border-radius: 12px;
  border: none;
  outline: none;
  font-size: 1em;
  cursor: pointer;
}
